/**
 * hooks/useFirebase.js
 */
import { useEffect, useState, useContext, createContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getMessaging, onMessage } from "firebase/messaging";

import { initializeApp } from "firebase/app";
import { getPerformance } from "firebase/performance";

import { setFcmToken, getUserData } from '@/core/store/reducer/authSlice';
import { showNotification } from '@/core/store/reducer/pushNotifSlice';
import { addItems } from '@/core/store/reducer/notificationSlice';

import { firebaseConfig } from "@/configs/Firebase";
import firebaseMessaging from "./firebaseMessaging";
import { logEvent } from './analytics';

// Initialize Firebase 
export const fbApp = initializeApp(firebaseConfig);

export const FirebaseContext = createContext();
export const FirebaseProvider = ({ children }) => {
    const resultState = useFirebaseProvider();
    return (
        <FirebaseContext.Provider value={resultState}>
            {children}
        </FirebaseContext.Provider>
    );
}

FirebaseProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export function useFirebaseProvider() {
    // const [isMounted, setMounted] = useState(null);
    const [performance, setPerformance] = useState(null);
    const dispatch = useDispatch();
    const dataUser = useSelector(getUserData);

    useEffect(() => {
        if (!performance) {
            // Initialize Performance Monitoring and get a reference to the service
            const perfInst = getPerformance(fbApp);
            setPerformance(perfInst);
        }

        const retrieveToken = async () => {
            try {
                if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
                    // Retrieve the notification permission status
                    const permission = await Notification.requestPermission();

                    // Check if permission is granted before retrieving the token
                    if (permission === 'granted') {
                        const token = await firebaseMessaging.init(fbApp);

                        dispatch(setFcmToken(token));

                        const messaging = getMessaging();
                    
                        const unsubscribe = onMessage(messaging, (payload) => {
                            const date = new Date();
                            const now = date.getTime();
                            const notifData = {
                                id: `notif-${now}`,
                                date,
                                isRead: false,
                                title: payload?.notification?.title,
                                content: payload?.notification?.body,
                                payload: payload?.data
                            };
                            dispatch(showNotification(notifData));
                    
                            firebaseMessaging.saveNotifications([notifData], dataUser?.uid, (addedItems) => dispatch(addItems(addedItems)));
                    
                            logEvent('notification_foreground', {
                                'message name': '',
                                'message time': date,
                                'message device time': date,
                                'message id': payload?.messageId,
                                'topic': payload?.notification?.title,
                                'label': '',
                                'message channel': '',
                                'message type': payload?.data?.category
                            });
                        });

                        return () => {
                            unsubscribe(); // Unsubscribe from the onMessage event
                        };
                    }
                }
            } catch (error) {
                console.log('An error occurred while retrieving token:', error);
            }
        };

        retrieveToken();
    }, []);

    return {
        performance,
    };
}

export const useFirebase = () => useContext(FirebaseContext);
