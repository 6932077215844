"use client";

// Import Library
import dynamic from "next/dynamic";
import { usePathname, useSearchParams } from "next/navigation";
import React, { useEffect, useRef } from "react";
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Script from "next/script";

// Import Custom Library
import { store } from '@/core/store';
import { FirebaseProvider } from "@/core/helpers/firebase";
import { logEvent, pageview } from '@/core/helpers/analytics';

// Import Component
import RootClientPage from "./root-client-page";

const ErrorMessage = dynamic(() => import('@/core/components/Modal/ErrorMessage'));
const Nudge = dynamic(() => import("@/components/Nudge"));
const PushNotif = dynamic(() => import("@/components/push-notif"));
const ResponseAction = dynamic(() => import('@/core/components/Modal/ResponseAction'));
const ResponseConfirmation = dynamic(() => import("@/core/components/Modal/ResponseConfirmation"));

import 'moment/locale/id';

export default function RootPage({ children, ...props }) {
    const refEngagementTime = useRef(null);
    const refTimerPageView = useRef(null);

    const pathname = usePathname();
    const searchParams = useSearchParams();

    const onBeforeUnload = () => {
        const newTimer = window.performance.now();
        let currentEngagementTime = 0;
        if (refEngagementTime.current) {
            currentEngagementTime = Math.round(newTimer - refEngagementTime.current);
        }

        logEvent('user_engagement', {
            'engagement time': currentEngagementTime
        });
    }

    useEffect(() => {
        if (props?.nudgesConfiguration) {
            if (+props?.nudgesConfiguration?.listVal1 === 1) {
                if (typeof smartech === 'function') {
                    // eslint-disable-next-line no-undef
                    smartech('create', props?.nudgesConfiguration?.listVal4);
                    // eslint-disable-next-line no-undef
                    smartech('register', props?.nudgesConfiguration?.listVal5);
                    // eslint-disable-next-line no-undef
                    smartech('identify', '');
                    // eslint-disable-next-line no-undef
                    // smartech('debug', '1');
                }
            }

            localStorage.setItem(process.env.NEXT_PUBLIC_SMARTECH_STORAGE_NAME, props?.nudgesConfiguration?.listVal1);
        }
    }, []);

    // Check version
    useEffect(() => {
        if (props?.checkVersion && props.version?.replace('-', '.')?.split('.')?.every(item => !isNaN(+item))) {
            localStorage.setItem(process.env.NEXT_PUBLIC_VERSION_STORAGE_NAME, props.version);
            if (props?.checkVersion?.forceUpdate) window.location.reload();
        }
    }, [pathname, searchParams]);

    useEffect(() => {
        refEngagementTime.current = window.performance.now();

        // user session tracking
        logEvent('session_start');

        // first load page view tracking
        logEvent('page_view', {
            'page location': window.location.href,
            'page referrer': '',
            'engagement time': 0,
        });

        // First visit tracking
        checkFirstVisit();

        // callback function to call when event triggers
        const onPageLoad = () => {
            refTimerPageView.current = setTimeout(() => {
                pageview();
            }, 0);
        };

        // // Check if the page has already loaded
        window.addEventListener('beforeunload', onBeforeUnload);
        window.addEventListener('load', onPageLoad, false);

        return () => {
            clearTimeout(refTimerPageView.current);

            window.removeEventListener('beforeunload', onBeforeUnload);
            window.removeEventListener('load', onPageLoad);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('load', checkFirstLoad);

        return () => {
            window.removeEventListener('load', checkFirstLoad);
        }
    }, []);

    const checkFirstLoad = () => {
        if (!sessionStorage.getItem('firstLoad') || sessionStorage.getItem('firstLoad') === '0') {
            sessionStorage.setItem('firstLoad', '1');
        }
    }

    const checkFirstVisit = async () => {
        const storeVisit = localStorage.getItem('IDEAL_FIRST_VISIT');
        if (!storeVisit) {
            logEvent('first_visit');
            localStorage.setItem('IDEAL_FIRST_VISIT', '1');
        }
    }

    return (
        <ReduxProvider store={store}>
            <PersistGate persistor={store.__persistor}>
                <FirebaseProvider>
                    <Script id="midtrans-script" type="text/javascript"
                        rel="preload"
                        src="https://api.midtrans.com/v2/assets/js/midtrans-new-3ds.min.js"
                        data-environment="sandbox"
                        data-client-key={props?.midtransKey?.listVal1}></Script>
                    <RootClientPage {...props}>
                        {children}
                    </RootClientPage>
                    <ErrorMessage />
                    <ResponseAction />
                    <ResponseConfirmation />
                    <PushNotif />
                    <Nudge />
                </FirebaseProvider>
            </PersistGate>
        </ReduxProvider >
    )
}
