'use client';

/**
 * useRequest
 */
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useSelector, useDispatch } from 'react-redux';
import { usePathname, useRouter, useSearchParams } from "next/navigation";

import Api from '@/configs/Api';
import { getUserData, setAuthData, clearAuthData, getFcmToken } from '@/core/store/reducer/authSlice';
import { setResponseAction } from '@/core/store/reducer/apiSlice';
import { signature } from './useFetch';

let isRefresh = false;
let newAccessToken = '';
let isStop = false;

const useRequest = () => {
    const pathname = usePathname();
    const router = useRouter();
    const searchParams = useSearchParams();

    const dispatch = useDispatch();

    const dataUser = useSelector(getUserData);
    const fcmToken = useSelector(getFcmToken);
    const instance = axios.create();

    instance.interceptors.request.use(async (config) => {
        const data = await signature(config?.url);

        config.headers['x-signature'] = data?.['x-signature'];
        config.headers['x-timestamp'] = data?.['x-timestamp'];

        if (!isRefresh || !config.headers.Authorization) {
            if (dataUser?.accessToken && dataUser?.accessToken !== '') {
                config.headers.Authorization = `Bearer ${dataUser?.accessToken}`;
            } else {
                delete config.headers.Authorization;
            }
        }

        return config;
    });

    instance.interceptors.response.use(
        (response) => {
            return response
        },
        async (error) => {
            const config = error?.config;
            const errorStatus = error?.response?.status;
            const errorException = error?.response?.data?.ExceptionType;

            if (+errorStatus === 401) {
                if (errorException === "SignatureHeaderApiTokenException") {
                    return Promise.reject(error);
                } if (config.headers.Authorization && newAccessToken && config.headers.Authorization === newAccessToken) {
                    return Logout();
                } else {
                    if (isRefresh) {
                        if (isStop) {
                            isRefresh = false;

                            return;
                        }

                        config.headers = {
                            ...config.headers,
                            Authorization: newAccessToken,
                        };

                        return instance(config);
                    }

                    isRefresh = true;

                    const response = await doRefreshToken(dataUser?.refreshToken, dataUser?.memberUID);

                    if (response?.data) {
                        const resData = response?.data;

                        newAccessToken = `Bearer ${resData?.accessToken}`;

                        dispatch(setAuthData({
                            ...dataUser,
                            ...resData
                        }));

                        config.headers = {
                            ...config.headers,
                            Authorization: newAccessToken,
                        };

                        setTimeout(() => {
                            isRefresh = false;
                        }, 5000);

                        return instance(config);
                    } else {
                        return Promise.reject(error);
                    }
                }
            } else if (+errorStatus === 403) {
                dispatch(clearAuthData());
                router.push('/');
            } else {
                return Promise.reject(error);
            }
        }
    );

    const getRequestHeaders = (customHeaders = {}) => {
        let headers = Object.assign(Api.AXIOS.defaults.headers, customHeaders);
        return headers;
    }

    const requestMain = (method, url, data, headers = {}) => {

        if (data && data?.trim !== false) {
            for (const item of Object.keys(data)) {
                if (!item?.toLowerCase()?.includes('password') && typeof data?.[item] === 'string') data[item] = data?.[item]?.trim();
            }
        }

        if (data instanceof URLSearchParams) {
            if (dataUser?.accessToken) data.append('device', jwtDecode(dataUser?.accessToken)?.device);
        } else if (data instanceof FormData) {
            if (dataUser?.accessToken) data.append('device', jwtDecode(dataUser?.accessToken)?.device);
        } else {
            data = {
                ...data,
                device: dataUser?.accessToken ? jwtDecode(dataUser?.accessToken)?.device : null
            }
        }

        if (method === 'get') {
            return instance.request({
                url,
                params: data,
                method,
                headers: Object.assign(Api.AXIOS.defaults.headers, headers),
                timeout: 120000,
                timeoutErrorMessage: 'The request reached its time limit'
            });
        }

        return instance.request({
            url,
            data,
            method,
            headers: Object.assign(Api.AXIOS.defaults.headers, headers),
            timeout: 120000,
            timeoutErrorMessage: 'The request reached its time limit'
        });
    }

    const requestGet = (url, params = {}, headers = {}) => {
        return requestMain('get', url, params, getRequestHeaders(headers));
    }

    const requestPost = (url, data, headers = {}) => {
        return requestMain('post', url, data, getRequestHeaders(headers));
    }

    const requestPut = (url, data, headers = {}) => {
        return requestMain('put', url, data, getRequestHeaders(headers));
    }

    const requestDelete = (url, data, headers = {}) => {
        return requestMain('delete', url, data, getRequestHeaders(headers));
    }

    const doRefreshToken = async (refreshToken, memberUID) => {
        const dataSignature = await signature(Api.REFRESH_TOKEN());

        try {
            const response = await axios
                .request({
                    url: Api.REFRESH_TOKEN(),
                    method: 'post',
                    data: {
                        refreshToken,
                        memberUID,
                        fcmToken: fcmToken,
                    },
                    headers: Object.assign(Api.AXIOS.defaults.headers, {
                        'x-signature': dataSignature?.['x-signature'],
                        'x-timestamp': dataSignature?.['x-timestamp'],
                    }),
                });

            return response;
        } catch (_error) {
            Logout();
        }
    }

    const Logout = () => {
        isStop = true;
        newAccessToken = '';

        const payToken = dataUser?.payToken;

        if (payToken || pathname?.includes('pay-mobile')) {
            const message = {
                status: 401,
                data: {
                    message: 'Sesi telah habis',
                    route: 'GoHome',
                }
            }

            // eslint-disable-next-line no-undef
            channel_response.postMessage(JSON.stringify(message));
        } else {
            dispatch(setResponseAction({
                message: <>
                    <p>Sesi kamu habis, silahkan login kembali.</p>
                </>,
                status: true,
            }));
    
            dispatch(clearAuthData());

            const isRedirect = searchParams.get('redirect');

            if (!isRedirect) router.push(`/login?redirect=${pathname}${searchParams.size > 0 ? `?${searchParams.toString()}` : ''}`);
            else router.push(`${pathname}${searchParams.size > 0 ? `?${searchParams.toString()}` : ''}`);
        }
    }

    return {
        requestGet,
        requestPost,
        requestPut,
        requestDelete,
    };
}

export default useRequest;