import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseMessaging = {
  onMessage: async () => {
    const messaging = getMessaging();
    onMessage(messaging, () => {
      // console.log("Message received. ", payload);
      alert("Notificacion");
    });
  },

  init: async function (app) {
    try {
      const messaging = getMessaging(app);

      const currentToken = await getToken(messaging, {
        vapidKey: process.env.NEXT_PUBLIC_FIREBASE_PUSH_CERT,
      });

      if (currentToken) {
        window.localStorage.setItem("fcm_token", currentToken);

        return currentToken;
      } else {
        console.log(
          "NOTIFICATION, No registration token available. Request permission to generate one."
        );
      }

      return currentToken;
    } catch (error) {
      console.error(error?.message);
      console.log(
        "NOTIFICATION, An error occurred while retrieving token . "
      );
    }
  },

  getMessages: async () => {
    const messages = window.localStorage.getItem("fcm_messages");
    return messages;
  },

  setMessages: async (messages) => {
    window.localStorage.setItem("fcm_messages", JSON.stringify(messages));
  },

  addMessage: async (message) => {
    const dataMessages = window.localStorage.getItem("fcm_messages");
    let newMessages = [];
    if (dataMessages) {
      let currentMessages = JSON.parse(dataMessages);

      if (typeof currentMessages === 'string') currentMessages = JSON.parse(currentMessages);

      newMessages = [message, ...currentMessages];
    } else {
      newMessages = [message];
    }
    window.localStorage.setItem("fcm_messages", JSON.stringify(newMessages));
  },

  removeMessage: async (id) => {
    const dataMessages = window.localStorage.getItem("fcm_messages");
    if (dataMessages) {
      let currentMessages = JSON.parse(dataMessages);

      if (typeof currentMessages === 'string') currentMessages = JSON.parse(currentMessages);

      const newMessages = currentMessages.filter((check) => check.id === id);

      window.localStorage.setItem("fcm_messages", JSON.stringify(newMessages));
    }
  },

  changeReadStatus: async (id, status = true) => {
    const dataMessages = window.localStorage.getItem("fcm_messages");
    if (dataMessages) {
      let currentMessages = JSON.parse(dataMessages);

      if (typeof currentMessages === 'string') currentMessages = JSON.parse(currentMessages);

      const msgDetailKey = currentMessages.findIndex((check) => check.id === id);

      if (msgDetailKey !== -1) {
        currentMessages[msgDetailKey].isRead = status;
      }

      window.localStorage.setItem("fcm_messages", JSON.stringify(currentMessages));
    }
  },

  changeAllReadStatus: async (status = true) => {
    const dataMessages = window.localStorage.getItem("fcm_messages");
    if (dataMessages) {
      let currentMessages = JSON.parse(dataMessages);

      if (typeof currentMessages === 'string') currentMessages = JSON.parse(currentMessages);

      currentMessages.forEach((message) => {
        message.isRead = status;
      })

      window.localStorage.setItem("fcm_messages", JSON.stringify(currentMessages));
    }
  },

  saveLocalNotifications: async (items) => {
    let storageData = window.localStorage.getItem(`${process.env.NEXT_PUBLIC_STORAGE_NAME}__notifications`);
    let localNotif = storageData ? JSON.parse(storageData) : {};

    if (typeof localNotif === 'string') localNotif = JSON.parse(localNotif);

    items.forEach((item) => {
      const notifMemberUid = item?.payload?.member_uid;
      if (notifMemberUid) {
        if (typeof localNotif[notifMemberUid] === 'undefined') {
          localNotif[notifMemberUid] = [item];
        } else {
          const isExists = localNotif?.[notifMemberUid].find((check) => check?.id === item?.id);
          if (!isExists) {
            localNotif[notifMemberUid] = [...localNotif[notifMemberUid], item];
          }
        }
      }
    });

    window.localStorage.setItem(
      `${process.env.NEXT_PUBLIC_STORAGE_NAME}__notifications`,
      JSON.stringify(localNotif)
    );
  },

  saveNotifications: async (items, currentUserUid, addItemsCallback) => {
    let storageData = window.localStorage.getItem(`${process.env.NEXT_PUBLIC_STORAGE_NAME}__notifications`);
    let localNotif = storageData ? JSON.parse(storageData) : {};

    if (typeof localNotif === 'string') localNotif = JSON.parse(localNotif);

    items.forEach((item) => {
      const notifMemberUid = item?.payload?.member_uid;
      if (notifMemberUid) {
        if (typeof localNotif[notifMemberUid] === 'undefined') {
          localNotif[notifMemberUid] = [item];
        } else {
          const isExists = localNotif?.[notifMemberUid].find((check) => check?.id === item?.id);
          if (!isExists) {
            localNotif[notifMemberUid] = [...localNotif[notifMemberUid], item];
          }
        }

        if (currentUserUid) {
          if (currentUserUid === notifMemberUid) {
            addItemsCallback([item]);
          }
        }
      }
    });

    window.localStorage.setItem(
      `${process.env.NEXT_PUBLIC_STORAGE_NAME}__notifications`,
      JSON.stringify(localNotif)
    );
  },

  changeReadStatusNotification: async (notifId, userUid, newStatus = true) => {
    let storageData = window.localStorage.getItem(`${process.env.NEXT_PUBLIC_STORAGE_NAME}__notifications`);
    let localNotif = storageData ? JSON.parse(storageData) : {};

    if (typeof localNotif === 'string') localNotif = JSON.parse(localNotif);

    if (localNotif && typeof localNotif[userUid] !== 'undefined') {
      const notifItemIndex = localNotif[userUid]?.findIndex((check) => check?.id === notifId);
      if (notifItemIndex !== -1) {
        localNotif[userUid][notifItemIndex].isRead = newStatus;
      }
    }

    window.localStorage.setItem(
      `${process.env.NEXT_PUBLIC_STORAGE_NAME}__notifications`,
      JSON.stringify(localNotif)
    );
  }
};

export default firebaseMessaging;