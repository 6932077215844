// Import Library
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { usePathname, useSearchParams } from "next/navigation";

// Import Custom Library
import Api from "@/configs/Api";
import useRequest from "@/core/api/useRequest";
import { setPropertyCategory } from "@/core/store/reducer/kompasSlice";

export default function RootClientPage({ children, ...props }) {

    const dispatch = useDispatch();

    const pathname = usePathname();
    const searchParams = useSearchParams();

    const { requestPost } = useRequest();

    const rootPathname = ['/', '/home'];

    useEffect(() => {
        checkUTM();

        const listPropertyCategory = props?.propertyCategory;

        dispatch(setPropertyCategory({
            primary: listPropertyCategory?.find(item => item?.listVal1?.toLowerCase() === 'primary'),
            secondary: listPropertyCategory?.find(item => item?.listVal1?.toLowerCase() === 'secondary'),
            takeover: listPropertyCategory?.find(item => item?.listVal1?.toLowerCase() === 'takeover'),
            multiguna: listPropertyCategory?.find(item => item?.listVal1?.toLowerCase() === 'refinance'),
            takeoverMultiguna: listPropertyCategory?.find(item => item?.listVal1?.toLowerCase() === 'takeover refinance'),
        }))
    }, []);

    const checkUTM = () => {
        let params = null;
        let query = [];

        for (const [key, value] of searchParams.entries()) {
            query = {
                ...query,
                [key]: value
            }
        }

        params = {
            deviceID: null,
            UTM_UID: query.utm_uid ?? null,
            applicationId: process.env.NEXT_PUBLIC_BASE_URL,
            adNetwork: query.utm_id ?? null,
            source: query.utm_source ?? null,
            medium: query.utm_medium ?? null,
            term: query.utm_term ?? null,
            content: query.utm_content ?? null,
            name: query.utm_campaign ?? null,
            internal: query.utm_uid ? true : false,
        };

        delete query.utm_uid;
        delete query.utm_id;
        delete query.utm_source;
        delete query.utm_medium;
        delete query.utm_term;
        delete query.utm_content;
        delete query.utm_campaign;

        params = {
            ...params,
            additionalInfo: Object.keys(query).length > 0 ? JSON.stringify(query) : null
        }

        if (rootPathname?.some(item => item?.toLowerCase() === pathname?.toLowerCase())) {
            requestPost(Api.UTM_TRACKING(), params).then(res => {
                if (res?.data?.uid) localStorage.setItem('utmUID', res?.data?.uid ?? '');
            }).catch(err => {
                console.log('UTM Tracking', err?.response?.data);
            });
        } else if (params.UTM_UID) {
            requestPost(Api.UTM_TRACKING(), params).then(res => {
                if (res?.data?.uid) localStorage.setItem('utmUID', res?.data?.uid ?? '');
            }).catch(err => {
                console.log('UTM Tracking', err?.response?.data);
            });
        }
    }

    return children
}
